.header-certificate-search-container {
  position: absolute;
  bottom: 0;
}

.header-certificate-search-link {
  color: #fff;
  font-size: 1.2em;
  text-decoration: none;
  padding: 8px 16px;
}

.header-certificate-search-link:hover {
  background-color: #008A61;
}

.header-certificate-search-link-active {
  background-color: #008A61;
}