main form {
    display: flex;
    margin-top:30px;
    margin-bottom:30px;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
}

main .search-content {
    margin-left: 30px;
    margin-right: 30px;
}

main form .form-control {
    flex: 1 1 28%;    
    margin: 5px;
}