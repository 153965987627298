
h1, h2, h3 {
    text-transform: uppercase;
}

h2 {
    font-size: 56px !important;
    text-shadow: 0px 0px 0px rgba(0,0,0,0.3);
    padding: 0;
    margin: 0;
    line-height: 1;
    font-weight: 200;
}

.App-logo img {
    animation-name: App-logo-spin;
    animation-duration: 3s;
}

.App-header {
    position: relative;
    padding-right: 0;
    padding-left: 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #176347;
    color: white;
    margin: -8px 0px 40px;
}

    .App-header .customer-info {
        justify-content: right;
        text-align: right;
        flex-wrap: wrap;
        width: 60%;
        margin-right: 30px;
        color: white !important;
    }

    .App-header img {
        width: 180px;
    }

@keyframes App-logo-spin {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}
