.logo-img {
    width: 180px;
    vertical-align: middle;
    display: inline-block;
    height: auto;
    max-width: 100%;
    border: none;
    border-radius: 0;
    box-shadow: none;
}

@media (max-width: 767px) {
    img {
        width: 70px;
    }
}

