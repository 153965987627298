main .login-content{
    width:33%;
    margin:auto;
}

main form {
    display: flex;
    flex-wrap: wrap;
}

main form .form-control {
    margin: 5px;
}

.loginButton {
    color: #176347 !important;
}
