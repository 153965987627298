main form.data-search-form {
    display: flex;
    margin-top:30px;
    margin-bottom:30px;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
}

main .search-content {
    margin-left: 30px;
    margin-right: 30px;
}

main form.data-search-form .form-control {
    flex: 2 1 25%;    
    margin: 5px;
}

main h3 {
    text-align: center;
    margin-top:20px;
}

.data-search-date-range {
    flex: 1 1 20%;
}

/*
    Filler is used to fill out the middle part of the form,
    so the date picker is floating left and the buttons are floating right
*/
.data-search-date-range-filler {
    flex: 1 1 30%;
}

.data-form-search-button {
    flex: initial;
    margin: 5px;
}
.greenTableRow {
    background-color: #85C285;
}
